<template>
  <div id="style3">
    <v-app>
      <v-row wrap v-if="records.length == 0">
        <v-col align="center" cols="12" md="12">
          <h3 class="red--text">No records found.</h3>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="records.length > 0">
        <div v-for="item in records">
          <v-col cols="12">
            <div class="senior-members-cards" v-on:click="previewData(item, $event)">
              <div class="senior-members-cards-bg">
                <div class="senior-members-cards-container" :style="item.StyleName">
                  <img class="senior-members-img" :src="item.PhotoPath" />
                  <div class="senior-members-details">
                    <h1 class="senior-members-title">
                      {{ item.Fullname }}
                    </h1>
                    <p class="senior-members-subtitle">
                      {{ item.DesignationName }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- {{ StyleIncrement() }} -->
            </div>
          </v-col>
        </div>
      </v-row>
    </v-app>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {
      styles: [
        "background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%)",
        "background: linear-gradient(120deg, rgba(14,0,255,1) 0%, rgba(0,212,255,1) 100%)",
        "background: linear-gradient(120deg, rgba(255,0,0,1) 0%, rgba(255,222,0,1) 100%)",
        "background: linear-gradient(120deg, rgba(0,255,4,1) 0%, rgba(255,222,0,1) 100%);",
        "background: linear-gradient(120deg, rgba(0,255,4,1) 0%, rgba(0,223,255,1) 100%);",
        "background: linear-gradient(120deg, #f953c6 0%, #b91d73 100%);",
        "background: linear-gradient(120deg, #a8ff78 0%, #a8ff78 100%);",
        "background: linear-gradient(120deg, #CAC531 0%, #F3F9A7 100%);",
        "background: linear-gradient(120deg, #74ebd5 0%, #ACB6E5 100%);",
        "background: linear-gradient(120deg, #7F00FF 0%, #E100FF 100%);",
        "background: linear-gradient(120deg, #F2994A 0%, #F2C94C 100%);",
        "background: linear-gradient(120deg, #B2FEFA 0%, #0ED2F7 100%);",
        "background: linear-gradient(120deg, #F7971E 0%, #F7971E 100%);",
        "background: linear-gradient(120deg, #6190E8 0%, #6190E8 100%);",
        "background: linear-gradient(120deg, #4568DC 0%, #4568DC 100%);",
        "background: linear-gradient(120deg, #43C6AC 0%, #F8FFAE 100%);",
        "background: linear-gradient(120deg, #FFAFBD 0%, #ffc3a0 100%);",
        "background: linear-gradient(120deg, #f12711 0%, #f5af19 100%);",
        "background: linear-gradient(120deg, #3494E6 0%, #EC6EAD 100%);",
        "background: linear-gradient(120deg, #ee0979 0%, #ff6a00 100%);",
        "background: linear-gradient(120deg, #A770EF 0%, #FDB99B 100%);",
        "background: linear-gradient(120deg, #00c3ff 0%, #ffff1c 100%);",
        "background: linear-gradient(120deg, #ef32d9 0%, #89fffd 100%);",
        "background: linear-gradient(120deg, #56ab2f 0%, #a8e063 100%);",
        "background: linear-gradient(120deg, #00d2ff 0%, #928DAB 100%);",
        "background: linear-gradient(120deg, #FF5F6D 0%, #FFC371 100%);",
      ],
      getdata: 0,
      styleCount: 0,
      i: 0,
    };
  },
  components: {},
  mounted() {
    // this.GetRecords();
  },
  methods: {
    GetRecords() {
      this.getdata = this.records.length;
      this.styleCount = this.styles.length;
      console.log(this.styleCount);
    },
    StyleIncrement() {
      this.i = this.i + 1;
      if (this.i == this.styleCount) {
        this.i = this.i - this.styleCount;
      }
    },
    previewData(tr, e) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        // this.$session.set("EditAwardsId", AwardsId);
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    /*
            var user = this.$session.get("user");
            console.log("user=" + JSON.stringify(user));
            var rolecode = user == (null || undefined) ? "" : user.role_code;
            console.log("rolecode=" + rolecode);
            if(rolecode != 1){
                var message= "You could't access in this page."
                this.$router.push({ name: "home", params: { error: message } })
            } 
            */
  },
};
</script>

<style lang="scss">
#style3 {
  //senior-members
  .senior-members-cards {
    position: relative;
    width: 180px;
    height: 300px;
    padding: 5px;
    margin: 10px 10px 20px 10px;
  }
  .senior-members-cards-bg {
    position: absolute;
    //   transform: rotatez(14deg);
    width: 100%;
    transition-property: z-index;
    background: #fcf8f4;
    filter: drop-shadow(2px 6px 3px rgba(0, 0, 0, 0.4));
    border-radius: 10px;
    transition-duration: 0.3s;
  }
  .senior-members-cards-container {
    z-index: 2;
    height: 100%;
    width: 90%;
    margin: 6% 5%;
    //   background-color: #DADDDF;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
  .senior-members-img {
    width: 75%;
    margin-top: 90%;
    margin-left: 50%;
    transform: translateX(-50%) translateY(-110%) rotatez(0deg);
    transition-property: width, transform;
    transition-duration: 0.3s;
    border-radius: 10%;
    clip-path: polygon(
      50% 0%,
      83% 12%,
      100% 43%,
      94% 78%,
      68% 100%,
      32% 100%,
      6% 78%,
      0% 43%,
      17% 12%
    );
  }

  .senior-members-details {
    margin-top: -80%;
    text-align: center;
  }
  .senior-members-title {
    font-size: 18px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.8px;
    word-spacing: 0px;
    text-decoration: rgb(68, 68, 68);
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 1px 3px 0 #969696, 1px 7px 5px #aba8a8;
    cursor: context-menu;
  }

  .senior-members-subtitle {
    margin-top: -8px;
    font-family: Georgia, serif;
    font-size: 16px;
    letter-spacing: 0.4px;
    word-spacing: 0px;
    color: #000000;
    text-decoration: rgb(68, 68, 68);
    font-style: oblique;
    text-transform: capitalize;
    cursor: context-menu;
  }

  .senior-members-cards:hover .senior-members-cards-bg {
    z-index: 10;
    position: absolute;
  }
  .senior-members-cards:hover .senior-members-cards-bg {
    //   transform: translate(5.5%, 0%) scale(1.3);
    transform: scale(1.3);
    transition: 0.3s;
  }
}
</style>