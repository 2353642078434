<template>
  <div id="individual-display-data">
    <v-container>
      <v-row wrap>
        <v-col cols="12" md="4">
          <h5 class="blue--text text--lighten">LOM Name</h5>
          <p>{{ records.LomName }}</p>
        </v-col>
        <v-col cols="12" md="4">
          <h5 class="blue--text text--lighten">Charter Date</h5>
          <p>{{ records.CharterDateTxt }}</p>
        </v-col>
      </v-row>
      <br />
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {},
  beforeMount() {},
};
</script>
