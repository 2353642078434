<template>
  <div id="style5">
    <v-app>
      <v-row wrap v-if="records.length == 0">
        <v-col align="center" cols="12" md="12">
          <h3 class="red--text">No records found.</h3>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="records.length > 0">
        <div v-for="item in records">
          <v-col cols="12">
            <div class="gb-cards" v-on:click="previewData(item, $event)">
              <div class="gb-cards-container" :style="item.StyleName">
                <img class="gb-img" :src="item.PhotoPath" />
                <div class="gb-details">
                  <h1 class="gb-title">{{ item.Fullname }}</h1>
                  <p class="gb-subtitle">{{ item.DesignationName }}</p>
                </div>
              </div>
              <!-- <v-icon
                icon
                size="25px"
                color="primary"
                @click="previewData(item, e)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file-search
              </v-icon> -->
              <!-- <p v-if="i <= getdata "> {{ StyleIncrement() }} </p> -->
            </div>
          </v-col>
        </div>
      </v-row>
    </v-app>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {
      styles: [
        "background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%)",
        "background: linear-gradient(120deg, rgba(14,0,255,1) 0%, rgba(0,212,255,1) 100%)",
        "background: linear-gradient(120deg, rgba(255,0,0,1) 0%, rgba(255,222,0,1) 100%)",
        "background: linear-gradient(120deg, rgba(0,255,4,1) 0%, rgba(255,222,0,1) 100%);",
        "background: linear-gradient(120deg, rgba(0,255,4,1) 0%, rgba(0,223,255,1) 100%);",
        "background: linear-gradient(120deg, #f953c6 0%, #b91d73 100%);",
        "background: linear-gradient(120deg, #a8ff78 0%, #a8ff78 100%);",
        "background: linear-gradient(120deg, #CAC531 0%, #F3F9A7 100%);",
        "background: linear-gradient(120deg, #74ebd5 0%, #ACB6E5 100%);",
        "background: linear-gradient(120deg, #7F00FF 0%, #E100FF 100%);",
        "background: linear-gradient(120deg, #F2994A 0%, #F2C94C 100%);",
        "background: linear-gradient(120deg, #B2FEFA 0%, #0ED2F7 100%);",
        "background: linear-gradient(120deg, #F7971E 0%, #F7971E 100%);",
        "background: linear-gradient(120deg, #6190E8 0%, #6190E8 100%);",
        "background: linear-gradient(120deg, #4568DC 0%, #4568DC 100%);",
        "background: linear-gradient(120deg, #43C6AC 0%, #F8FFAE 100%);",
        "background: linear-gradient(120deg, #FFAFBD 0%, #ffc3a0 100%);",
        "background: linear-gradient(120deg, #f12711 0%, #f5af19 100%);",
        "background: linear-gradient(120deg, #3494E6 0%, #EC6EAD 100%);",
        "background: linear-gradient(120deg, #ee0979 0%, #ff6a00 100%);",
        "background: linear-gradient(120deg, #A770EF 0%, #FDB99B 100%);",
        "background: linear-gradient(120deg, #00c3ff 0%, #ffff1c 100%);",
        "background: linear-gradient(120deg, #ef32d9 0%, #89fffd 100%);",
        "background: linear-gradient(120deg, #56ab2f 0%, #a8e063 100%);",
        "background: linear-gradient(120deg, #00d2ff 0%, #928DAB 100%);",
        "background: linear-gradient(120deg, #FF5F6D 0%, #FFC371 100%);",
      ],
      getdata: 0,
      styleCount: 5,
      i: 0,
    };
  },
  components: {},
  mounted() {
    this.GetRecords();
  },
  methods: {
    GetRecords() {
      var n1 = this.records.length;
      console.log("GoverningBoardMembers n1=" + n1);
      this.getdata = n1;
    },
    StyleIncrement() {
      var i = parseInt(this.i);
      var n1 = this.getdata;
      var n2 = this.styles.length;
      console.log(
        "GoverningBoardMembers StyleIncrement i=" +
          i +
          ", n1=" +
          n1 +
          ", n2=" +
          n2
      );
      if (i <= n1) {
        this.i = i == n2 ? n1 - i : i + 1;
      }
    },
    previewData(tr, e) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        // this.$session.set("EditAwardsId", AwardsId);
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    /*
            var user = this.$session.get("user");
            console.log("user=" + JSON.stringify(user));
            var rolecode = user == (null || undefined) ? "" : user.role_code;
            console.log("rolecode=" + rolecode);
            if(rolecode != 1){
                var message= "You could't access in this page."
                this.$router.push({ name: "home", params: { error: message } })
            } 
            */
  },
};
</script>

<style lang="scss">
#style5 {
  //members
  .gb-cards {
    width: 180px;
    perspective: 1000px;
    margin: 10px;
  }
  .gb-cards-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
  }
  .gb-cards-container::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(
      226deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 35%,
      rgba(255, 255, 255, 0.2) 42%,
      rgba(255, 255, 255, 0) 60%
    );
    transform: translateY(-20%);
    will-change: transform;
    transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
  }
  .gb-cards-container .gb-img {
    z-index: 5;
    display: block;
    width: 68%;
    margin: 10% auto;
    box-shadow: 0 0 5px black;
    outline: 5px ridge #f6d365;
    outline-offset: 0px;
    /* clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%); */
  }
  .gb-cards:hover .gb-cards-container {
    transform: rotateX(7deg) translateY(-6px);
  }
  .gb-cards:hover .gb-cards-container::after {
    transform: translateY(0%);
  }
  .gb-cards::before {
    display: block;
    content: "";
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 6px 12px 12px rgba(0, 0, 0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
  }
  .gb-cards:hover::before {
    opacity: 0.8;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
  }

  .gb-details {
    margin-top: 10%;
    text-align: center;
  }
  .gb-title {
    font-size: 18px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-weight: 600;
    letter-spacing: 0.8px;
    text-decoration: rgb(68, 68, 68);
    text-transform: uppercase;
    color: #ffffff;
    background: #000;
    cursor: context-menu;
  }

  .gb-subtitle {
    margin-top: -5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000000;
    text-decoration: rgb(68, 68, 68);
    text-transform: capitalize;
    cursor: context-menu;
  }
}
</style>