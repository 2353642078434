<template>
  <div id="individual-display-data">
    <v-container class="py-0">
      <v-row wrap v-if="records.length == 0">
        <v-col align="center" cols="12" md="12">
          <h3 class="red--text">No records found.</h3>
        </v-col>
      </v-row>
      <v-row wrap v-if="records.length > 0">
        <v-col align="center" cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            class="elevation-1"
            :single-select="false"
            v-model="selected"
            :headers="tableColumns1"
            :items="records"
            :items-per-page="50"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
          >
            <template v-slot:item.ActiveStatusTxt="{ item }">
              <v-chip
                :color="getActiveStatusColor(item.ActiveStatusTxt)"
                draggable
                dark
                >{{ item.ActiveStatusTxt }}</v-chip
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    icon
                    size="25px"
                    color="primary"
                    @click="previewData(item, e)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-document-edit
                  </v-icon>
                </template>
                <span> Edit </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <br />
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {
      tableColumns1: [],
      selected: [],
      search: "",
      rows: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        // this.$session.set("EditAwardsId", AwardsId);
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    if (RoleId != "") {
      this.refreshPageData();
      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        this.tableColumns1.push(
          { text: "Status", value: "ActiveStatusTxt" },
          { text: "Membership Id", value: "MembershipId" },
          { text: "Member Name", value: "FirstName" },
          { text: "Mobile No", value: "PrimaryMobile" },
          { text: "Email Id", value: "EmailId" },
          { text: "Designation", value: "DesignationName" },
          { text: "Actions", value: "actions" }
        );
      } else {
        this.tableColumns1 = [];
      }
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>
